import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      data: {},
    };
  },
  getters: {
    getDataFromState(state) {
      return state.data;
    },
  },
  mutations: {
    addDataFromState(state, contactsData) {
      state.data = { ...contactsData };
      console.log(state.data);
    },
  },
});

export default store;
