<template>
  <div class="pagination">
      <div class="pagination__btn"
           v-for="(item, i) in valueCards"
           :key="item" v-html="pagination.svg"
           :class="{'active': i === activeCardIndex, 'pagination-category': classes}"
           @click="paginationEvent(i)"
      >
      </div>
  </div>
</template>

<script>
export default {
  name: "pagination",
  props: {
    valueCards: {
      type: Number,
      default: 0
    },
    activeCardIndex: {
      type: Number,
      default: 0,
    },
    colorPagination: {
      type: String,
      default: '#E6EBF5'
    },
    classes: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      pagination: {
        svg: `<svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="4" cy="4" r="4" fill="${this.colorPagination}"/>
            </svg>`
      }
    }
  },
  methods: {
    paginationEvent(i) {
      this.$emit('paginationEvent', i)
    }
  },
  computed: {

  }
}
</script>

<style lang="scss">
.pagination {
  display: none;
  justify-content: center;
  align-items: center;
}
.pagination__btn:not(:last-child) {
  margin-right: 8px;
}
</style>
