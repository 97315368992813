<template>
    <div class="tooltip"
         id="myTooltip"
    >
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99991 0.133301C6.44403 0.133301 4.92309 0.594673 3.62942 1.45907C2.33576 2.32347 1.32747 3.55208 0.732058 4.98952C0.136648 6.42697 -0.019138 8.00869 0.284399 9.53468C0.587936 11.0607 1.33716 12.4624 2.43734 13.5625C3.53751 14.6627 4.93922 15.4119 6.4652 15.7155C7.99118 16.019 9.57291 15.8632 11.0104 15.2678C12.4478 14.6724 13.6764 13.6641 14.5408 12.3705C15.4052 11.0768 15.8666 9.55585 15.8666 7.99997C15.8666 5.9136 15.0378 3.91268 13.5625 2.43739C12.0872 0.962108 10.0863 0.133301 7.99991 0.133301ZM8.66658 12.1777H7.33324V7.15552H8.66658V12.1777ZM8.66658 5.48886H7.33324V3.79997H8.66658V5.48886Z"
                  fill="#D9DAE2"/>
        </svg>
    </div>
</template>

<script>

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/scale-subtle.css';

export default {

    mounted() {
        tippy('#myTooltip', {
            content: "<p><strong>Исключение:</strong> пакет «Базовый»</p>",
            allowHTML: true,
            interactive: true,
            appendTo: () => document.body,
            maxWidth: 175,
            theme: 'tooltip__theme',
            offset: [0, 12],
            placement: 'top',
            animation: 'scale-subtle',
        });
    }
}
</script>
