<template>
  <div class="burger" :class="{ active: isOpen }">
    <!--        <div class="burger__all">-->
    <div class="burger__box">
      <div class="close__burger" @click="$emit('toggle')">
        <svg
          width="46"
          height="52"
          viewBox="0 0 46 52"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M14 18H32V20H14V18Z" fill="white" />
          <path d="M14 25H32V27H14V25Z" fill="white" />
          <path d="M14 32H32V34H14V32Z" fill="white" />
        </svg>

        <div class="burger__btn_close" v-if="isOpen">
          <svg
            width="8"
            height="12"
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.8284 5.65677L7.07095 1.41421L5.65674 0L-0.000115871 5.65685L1.4141 7.07107L1.41418 7.07098L5.65685 11.3137L7.07107 9.89944L2.8284 5.65677Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div class="header__logo burger__logo">
        <img src="../../assets/img/logo_online.svg" alt="Logo">
      </div>
      <nav class="navigation-burger">
        <ul class="navigation__list-burger">
          <a href="/#">
            <li class="navigation__link burger__link active">Главная</li>
          </a>
          <a href="#presentation">
            <li class="navigation__link burger__link">Как это работает</li>
          </a>
          <a href="#category">
            <li class="navigation__link burger__link">Кому подходит</li>
          </a>
          <a href="#additionally">
            <li class="navigation__link burger__link">Дополнительно</li>
          </a>
          <a href="#contacts">
            <li class="navigation__link burger__link">Контакты</li>
          </a>
        </ul>
      </nav>
<!--      <button-->
<!--        class="header__button btn__burger_ex"-->
<!--        id="myButton"-->
<!--        @click="$emit('redirect')"-->
<!--      >-->
<!--        войти-->
<!--      </button>-->
    </div>
  </div>
  <!--    </div>-->
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  name: 'burger',
};
</script>

<style scoped></style>
