<template>
  <div class="popup">
    <div class="popup__wrapper">
      <div class="complete container" v-if="steps === 3">
        <div><img src="../assets/img/complete.png" alt="complete" /></div>
        <h2 class="complete__title">Готово!</h2>
        <p class="complete__text">
          Спасибо, что заполнили анкету. <br />
          Мы свяжемся с вами в ближайшее время!
        </p>
        <button class="order__btn complete__btn" @click="$emit('close')">
          на сайт
        </button>
        <div class="close" @click="$emit('close')">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z"
              fill="#9FA2B5"
            />
          </svg>
        </div>
      </div>

      <div class="complete container" v-else-if="stepsError">
        <div><img width="96" src="../assets/img/error.png" alt="error" /></div>
        <h2 class="complete__title">Ошибка!</h2>
        <p class="complete__text">
          Что-то пошло не так. <br />
          Попробуйте отправить контакты позднее
        </p>
        <button class="order__btn complete__btn" @click="$emit('close')">
          Назад
        </button>
        <div class="close" @click="$emit('close')">
          <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
            <path
                d="M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z"
                fill="#9FA2B5"
            />
          </svg>
        </div>
      </div>

      <div class="order container" v-else>
        <div>
          <h2 class="order__title">{{ titleStep }}</h2>

          <div class="order__box">
            <div class="order__box_left">
              <ordering-step :steps="steps" :email="this.email" />
              <div class="order__btn_box mod">
                <button
                  class="order__btn_back"
                  :class="{ show: steps > 0 }"
                  @click="prevCard()"
                >
                  назад
                </button>
                <div class="order__btn_take">
                  <button
                    class="order__btn"
                    @click="nextCard()"
                    :disabled="!isActiveNextStep"
                    v-if="steps < 2"
                  >
                    далее
                  </button>

                  <button class="order__btn" @click="getData()" v-else>
                    заказать
                  </button>
                </div>
              </div>
            </div>

            <ordering-cards
              :steps="steps"
              :errors="errors"
              @selectWorker="selectWorker"
              @changeContacts="changeContacts"
              @selectCompanyVector="selectCompanyVector"
              @selectDirections="selectDirections"
              @changeCustomDirection="selectCustomDirection"
            >
              <div class="capcha">
                <vue-recaptcha
                  :sitekey="recaptcha"
                  v-show="steps === 2"
                  size="normal"
                  theme="light"
                  hl="ru"
                  @verify="recaptchaVerified"
                  @expire="recaptchaExpired"
                  @fail="recaptchaFailed"
                  ref="vueRecaptcha"
                >
                </vue-recaptcha>
              </div>
              <p class="personal_text mod_dec" v-show="steps === 2">
                Нажимая кнопку, вы автоматически соглашаетесь с
                <a href="/privacy.pdf" target="_blank"><span>политикой обработки персональных данных</span></a>
              </p>
            </ordering-cards>
          </div>
        </div>

        <div class="order__btn_box mobile_btn">
          <button
            class="order__btn_back"
            :class="{ show: steps > 0 }"
            @click="prevCard()"
          >
            назад
          </button>
          <div class="order__btn_take">
            <button
              class="order__btn"
              @click="nextCard()"
              :disabled="!isActiveNextStep"
              v-if="steps < 2"
            >
              далее
            </button>

            <button class="order__btn" @click="getData()" v-else>
              заказать
            </button>

            <p class="personal_text" v-show="steps === 2">
              Нажимая кнопку, вы автоматически соглашаетесь с
              <a href="/privacy.pdf" target="_blank"><span>политикой обработки персональных данных</span></a>
            </p>
          </div>
        </div>

        <div class="close" @click="$emit('close')">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M27 14.41L25.59 13L20 18.59L14.41 13L13 14.41L18.59 20L13 25.59L14.41 27L20 21.41L25.59 27L27 25.59L21.41 20L27 14.41Z"
              fill="#9FA2B5"
            />
          </svg>
        </div>

        <!--        <div class="capcha">-->
        <!--          <vue-recaptcha :site-key="recaptcha"-->
        <!--                         v-show="steps === 3"-->
        <!--                         size="normal"-->
        <!--                         theme="light"-->
        <!--                         hl="ru"-->
        <!--                         @verify="recaptchaVerified"-->
        <!--                         @expire="recaptchaExpired"-->
        <!--                         @fail="recaptchaFailed"-->
        <!--                         ref="vueRecaptcha"-->
        <!--          >-->

        <!--          </vue-recaptcha>-->
        <!--        </div>-->
        <!--        <p class="personal_text" v-show="steps === 3">Нажимая кнопку, вы автоматически-->
        <!--          соглашаетесь с <span>политикой обработки персональных данных</span></p>-->
      </div>
    </div>
  </div>
</template>

<script>
import orderingCards from './ordering/ordering.cards';
import orderingStep from './ordering/ordering.step';
// import axios from 'axios';
import vueRecaptcha from 'vue3-recaptcha2';
import axios from "axios";

export default {
  components: { orderingCards, orderingStep, vueRecaptcha },
  name: 'ordering-services',
  data() {
    return {
      API_URL: process.env.VUE_APP_API_URL,
      recaptcha: `${process.env.VUE_APP_RECAPTCHA_KEY}`,
      showRecaptcha: false,
      stepsError: false,
      steps: 0,
      titles: [
        'Шаг 1. Сфера компании',
        'Шаг 2. Количество сотрудников в компании',
        'Шаг 3. Оставьте контакты',
      ],
      titleStep: '',
      errors: {
        fullName: null,
        phone: null,
        email: null,
        captcha: null,
      },
      email: {
        captchaToken: null,
        companyVector: 'Не указано',
        worker: 'Не указано',
        contacts: {
          fullName: null,
          phone: null,
          email: null,
          company: null,
        },
      },
    };
  },
  watch: {
    'email.contacts.fullName': function () {
      this.errors.fullName = null;
    },
    'email.contacts.phone': function () {
      this.errors.phone = null;
    },
    'email.contacts.email': function () {
      this.errors.email = null;
    },
  },
  computed: {
    isActiveNextStep() {
      switch (this.steps) {
        case 0:
          return this.email.companyVector !== 'Не указано';
        case 1:
          return this.email.worker !== 'Не указано';
        case 2:
          return true;
        default:
          return false; // must be false
      }
    },
  },
  methods: {
    changeContacts(contacts) {
      this.email.contacts = Object.assign({}, contacts);
    },
    selectWorker(item) {
      this.email.worker = item;
    },
    selectCompanyVector(item) {
      this.email.companyVector = item.title;
    },
    selectDirections(item) {
      if (!this.email.directions.includes(item)) {
        this.email.directions.push(item);
        this.email.mutateDirections = this.email.directions.join(', ');
      } else {
        this.email.directions = this.email.directions.filter(
          (arr) => arr !== item
        );
        this.email.mutateDirections = this.email.directions.join(', ');
      }
    },
    selectCustomDirection(item) {
      this.email.customDirection = item;
    },
    getTitle() {
      this.titleStep = this.titles[this.steps];
    },
    nextCard() {
      if (this.steps !== 2) {
        this.steps++;
        this.getTitle();
      }
    },
    prevCard() {
      if (this.steps > 0) {
        this.steps--;
        this.getTitle();
      }
    },
    async getData() {
      if (
        this.email.contacts.fullName === null ||
        this.email.contacts.fullName === ''
      ) {
        this.errors.fullName = 'Введите ваше имя';
      } else {
        this.errors.fullName = null;
      }

      if (!this.isValidPhone(this.email.contacts.phone)) {
        this.errors.phone = 'Некорректный номер телефона';
      } else {
        this.errors.phone = null;
      }

      if (!this.isValidEmail(this.email.contacts.email)) {
        this.errors.email = 'Некорректный email';
      } else {
        this.errors.email = null;
      }

      if (this.email.captchaToken === null) {
        this.errors.captcha = 'Пройдите, пожалуйста, проверку';
      } else {
        this.errors.captcha = null;
      }

      if (
        !this.errors.captcha &&
        !this.errors.fullName &&
        !this.errors.email &&
        !this.errors.phone
      ) {
        const data = {
          company: this.email.contacts.company,
          email: this.email.contacts.email,
          phone: this.email.contacts.phone,
          fullName: this.email.contacts.fullName,
          captcha: this.email.captchaToken,
          count_persons: this.email.worker,
          scope: this.email.companyVector
        }

        try {
          await axios.post(this.API_URL, data)
          this.steps++
          this.stepsError = false
        } catch (e) {
          this.stepsError = true
        }
      }
    },

    isValidPhone(phone) {
      return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(phone);
    },
    isValidEmail(email) {
      return /^.+@.+\..+$/gim.test(email);
    },

    recaptchaVerified(response) {
      this.email.captchaToken = response;
    },
    recaptchaExpired() {
      this.email.captchaToken = null;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.email.captchaToken = null;
    },
  },
  mounted() {
    this.getTitle();
  },
};
</script>

<style scoped></style>
