import {createApp} from 'vue';
import App from './App.vue';
import "./assets/scss/app.scss";
import store from './store';
import axios from 'axios';
import Maska from "maska";
import Vue3TouchEvents from "vue3-touch-events";

const app = createApp(App)
app.use(Maska, axios)
app.use(store)
app.use(Vue3TouchEvents, {
    disableClick: true,
})
app.mount('#app')
