<template>
    <div class="plan__cards center">
                <div class="plan__cards_item" v-for="(card, index) in cards" :key="index">
                    <h3 class="plan__cards_title">{{card.title}}</h3>
                    <div class="plan__cards_text-container">
                        <div class="plan__cards_paragraph" v-for="(item, i) in card.list" :key="i">
                            <div :class="{'grey' : !item.active}">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <rect width="24" height="24" rx="12" fill="#EBF9EE"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M10.2857 17L6 12.1923L7.2 10.8462L10.2857 14.3077L16.8 7L18 8.34615L10.2857 17Z"
                                          fill="#34C759"/>
                                </svg>
                            </div>
                            <p class="plan__cards_text" :class="{active : item.active}">{{item.title}}</p>
                        </div>
                    </div>

                    <p class="plan__cards_price" v-if="hasPrice(card.price)"> {{ card.price[active] }} ₽ <span class="plan__cards_price_opacity">/ мес</span></p>
                    <p class="plan__cards_price" v-else><span class="plan__cards_price_opacity">Индивидуальный расчёт</span></p>

                    <div class="plan__cards_buttons">
                        <button class="plan__cards_button" @click="$emit('open')">Заказать</button>
                    </div>
                </div>
            </div>
</template>

<script>
export default {
    // eslint-disable-next-line vue/no-unused-components
    props: {
        active: {
            type: Number
        },
        cards: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                320: {
                    itemsToShow: 1,
                    snapAlign: 'center',
                },
                330: {
                    itemsToShow: 1.2,
                    snapAlign: 'center',
                },
                480: {
                    itemsToShow: 1.5,
                    snapAlign: 'center',
                },
                542: {
                    itemsToShow: 1.7,
                    snapAlign: 'center',
                },
                // 700px and up
                768: {
                    itemsToShow: 2,
                    snapAlign: 'start',
                },
                // 992 and up
                992: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                993: {
                    itemsToShow: 0,
                }
            }
        }
    },
    name: "plan.cards",
    methods: {
      hasPrice(prices) {
          if (prices === undefined) {
              return;
          }
        return prices[this.active] !== undefined;
      }
    }
};
</script>

<style scoped>

</style>
