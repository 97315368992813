<template>
  <div class="plan">
    <h2 class="plan__title center">Выберите план, который подходит именно вам</h2>
    <div class="plan__pointer">
      <button
          v-for="(button, i) in buttons" :key="i"
          class="plan__pointer_button"
          :class="{'active' : active === i}"
          v-html="button.title"
          @click="active = i"
      />
    </div>

    <plan-cards
        :active=active
        :cards="activeCards"
        @open="$emit('open')"
        ref="plan"
        v-touch:swipe.left="swipeLeft"
        v-touch:swipe.right="swipeRight"
    />
    <pagination
        @paginationEvent="paginationEvent"
        :activeCardIndex="activeCardIndex"
        :valueCards="valueCards"
    />
    <!--      <carousel-->
    <!--          :cards="cards"-->
    <!--      />-->
  </div>
</template>

<script>
import planCards from "./plan.cards";
import Pagination from "../pagination/pagination";

export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {Pagination, planCards,},
  name: "plan",
  data() {
    return {
      innerWidth: null,
      active: 1,
      valueCards: 4,
      buttons: [
        {
          title: '6 месяцев',
          cards: [1, 2]
        },
        {
          title: '12 месяцев',
          cards: [1, 2, 3, 4]
        },
        {
          title: '24 месяца',
          cards: [1, 2, 3, 4]
        }
      ],
      cards: [
        {
          id: 1,
          title: 'Базовый',
          list: [
            {title: '1 рабочее место', active: true},
            {title: '300 объектов проверки', active: true},
            {title: 'Деструктивное поведение', active: true},
            {title: 'Общественная активность', active: false},
            {title: 'Критика компании', active: false},
            {title: 'Панель аналитики и отчётности', active: false},
            {title: 'Тематики на основе семантического анализа', active: false},
            {title: 'Индивидуальное направление исследования на базе ИИ', active: false},
          ],
          price: [
            '60 000', '60 800', '48 650'
          ]
        },
        {
          id: 2,
          title: 'Оптимальный',
          list: [
            {title: '2 рабочих места', active: true},
            {title: '1000 объектов проверки', active: true},
            {title: 'Деструктивное поведение', active: true},
            {title: 'Общественная активность', active: true},
            {title: 'Критика компании', active: false},
            {title: 'Панель аналитики и отчётности', active: false},
            {title: 'Тематики на основе семантического анализа', active: false},
            {title: 'Индивидуальное направление исследования на базе ИИ', active: false},
          ],
          price: ['60 000', '100 000', '80 000']
        },
        {
          id: 3,
          title: 'Продвинутый',
          list: [
            {title: '5 рабочих мест', active: true},
            {title: '5 000 объектов проверки', active: true},
            {title: 'Деструктивное поведение', active: true},
            {title: 'Общественная активность', active: true},
            {title: 'Критика компании', active: true},
            {title: 'Панель аналитики и отчётности', active: false},
            {title: 'Тематики на основе семантического анализа', active: false},
            {title: 'Индивидуальное направление исследования на базе ИИ', active: false},
          ],
        },
        {
          id: 4,
          title: 'Индивидуальный',
          list: [
            {title: '50 рабочих мест', active: true},
            {title: '50 000 объектов проверки', active: true},
            {title: 'Деструктивное поведение', active: true},
            {title: 'Общественная активность', active: true},
            {title: 'Критика компании', active: true},
            {title: 'Панель аналитики и отчётности', active: true},
            {title: 'Тематики на основе семантического анализа', active: true},
            {title: 'Индивидуальное направление исследования на базе ИИ', active: true},
          ],
        },
      ],
      activeCardIndex: 0,
    };
  },
  beforeMount() {
    this.innerWidth = innerWidth
    console.log(this.innerWidth)
  },
  methods: {
    paginationEvent(i) {
      this.activeCardIndex = i
      this.$nextTick(() => {
        if (i === 0) {
          this.$refs.plan.$el.style.marginLeft = '0%'
        } else if (i === 1) {
          this.$refs.plan.$el.style.marginLeft = '-94%'
        } else if (i === 2) {
          this.$refs.plan.$el.style.marginLeft = '-188%'
        } else if (i === 3) {
          this.$refs.plan.$el.style.marginLeft = '-281%'
        }
      })
    },
    swipeLeft() {
      if(this.innerWidth > 320) {
        return
      }
      if (this.activeCardIndex < this.valueCards - 1) {
        this.activeCardIndex++
        this.paginationEvent(this.activeCardIndex)
      } else if (this.activeCardIndex === this.valueCards - 1) {
        this.activeCardIndex = 0
        this.paginationEvent(this.activeCardIndex)
      }
    },
    swipeRight() {
      if(this.innerWidth > 320) {
        return
      }
      if(this.activeCardIndex === 0) {
        this.activeCardIndex = this.valueCards - 1
        this.paginationEvent(this.activeCardIndex)
      } else if(this.activeCardIndex > 0) {
        this.activeCardIndex--
        this.paginationEvent(this.activeCardIndex)
      }
    }
  },
  computed: {
    activeCards() {
      return this.cards.filter((card) => this.buttons[this.active].cards.includes(card.id));
    }
  }
};
</script>

<style scoped>

</style>
