<template>
    <div class="stages" v-for="(title, i) in titles" :key="i">
        <div class="stages__step" :class="{'active' : i <= steps}">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8" cy="8" r="6" fill="white" stroke="#ECEDF1" stroke-width="4"/>
            </svg>
            <svg :style="{display: i === 2 ? 'none' : 'block'}" width="4" height="82" viewBox="0 0 4 82" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
                <rect width="4" height="82" rx="2" fill="#ECEDF1"/>
            </svg>
        </div>
        <div class="stages__text">
            <p class="stages__text_title" :class="{'active' : steps === i}">{{ title }}</p>
            <p class="stages__text_title_mini" :class="{'active' : steps === i}">{{ getStepText(i) }}</p>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        steps: {
            type: Number,
            default: 0
        },
        email: {
            type: Object,
            default: null
        }
    },
    name: "ordering.step",
    data() {
        return {
            titles: ['01. Сфера компании', '02. Количество сотрудников', '03. Оставьте контакты']
        };
    },
    methods: {
        getStepText(i) {
            switch (i) {
                case 0:
                    return this.email.companyVector;
                case 1:
                    return this.email.worker;
                case 2:
                    return this.email.mutateDirections;
                default:
                    return 'Не указано';
            }
        }
    }
};
</script>
